import axios from 'axios'
import router from "@/router";

const state = {
    auth_user: {},
    token: "",
    auth_errors: {},
    auth_loading: false,
    permissions: []
}

const getters = {
    auth_user: state => state.auth_user,
    token: state => state.token,
    auth_errors: state => state.auth_errors,
    auth_loading: state => state.auth_loading,
    permissions: state => state.permissions,
}

const mutations = {
    setAuthUser(state, auth_user) {
        state.auth_user = auth_user
    },
    setToken(state, token) {
        state.token = token
    },
    setPermissions(state, permissions) {
        state.permissions = permissions
    },
    setAuthLoading(state, auth_loading) {
        state.auth_loading = auth_loading
    },
    setAuthErrors(state, auth_errors) {
        state.auth_errors = auth_errors
    },
}

const actions = {
    async logout({commit, getters}) {
        commit('setAuthLoading', true)
        commit('setAuthErrors', {})
        try {
            let token = getters.token
            let response = await axios.post('/api/logout', {}, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
            commit('setAuthLoading', false)
            if (response.data.ok) {
                var doctor = {}
                window.localStorage.clear()
                commit('setAuth', doctor)
                return true
            } else {
                return false
            }
        } catch (error) {
            console.log(error);
            commit('setAuthLoading', false)
            commit('setAuthErrors', error.response.data.errors)
            return false
        }
    },
    async check_me({commit}, token) {
        commit('setAuthLoading', true)
        commit('setAuthErrors', {})
        try {
            let response = await axios.get('/api/admin/me', {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
            commit('setAuthLoading', false)
            commit('setAuthUser', response.data.data)
            commit('setPermissions', response.data.data.permissions)
            return {
                ok: true, status: response.status
            }
        } catch (error) {
            commit('setAuthLoading', false)
            router.push("/login")

            if (error.response.data && error.response.data.errors)
                commit('setAuthErrors', error.response.data.errors)
            return {ok: false, status: error.response.status}
        }
    },
    async login({commit, getters}, data) {
        commit('setAuthLoading', true)
        commit('setAuthErrors', {})
        try {
            let response = await axios.post('/api/admin/login', data)
            commit('setAuthLoading', false)
            if (response.data.ok) {
                commit('setToken', response.data.token)
                commit('setAuthUser', response.data.data)
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + getters.token;
                return true
            } else {
                return false
            }
        } catch (error) {
            console.log(error);
            commit('setAuthLoading', false)
            commit('setAuthErrors', error.response.data.errors)
            return false
        }
    },
    async register({commit, getters}, data) {
        commit('setAuthLoading', true)
        commit('setAuthErrors', {})
        try {
            let response = await axios.post('/api/register', data)
            commit('setAuthLoading', false)
            if (response.data.ok) {
                commit('setToken', response.data.token)
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + getters.token;
                commit('setAuthUser', response.data.data)
                commit('setAuthErrors', {})
                router.push({name: "email.verification.pending"})
                return true
            } else {
                return false
            }
        } catch (error) {
            console.log(error);
            commit('setAuthLoading', false)
            commit('setAuthErrors', error.response.data.errors)
            return false
        }
    },
    async updateAuthPassword({commit}, data) {
        commit('setAuthLoading', true)
        commit('setAuthErrors', {})
        try {
            let response = await axios.put('/api/update-password', data)
            commit('setAuthLoading', false)
            if (response.data.ok) {
                commit('setAuthErrors', {})
                return true
            } else {
                return false
            }
        } catch (error) {
            console.log(error);
            commit('setAuthErrors', error.response.data.errors)
            return false
        }
    },
    async resend({commit}, data) {
        commit('setAuthLoading', true)
        commit('setAuthErrors', {})
        try {
            let response = await axios.post('/api/email/verify/resend', data)
            commit('setAuthLoading', false)
            commit('setAuthErrors', {})
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setAuthLoading', false)
            commit('setAuthErrors', error.response.data.errors)
            return false
        }
    },
    async verifyEmail({commit}, data) {
        commit('setAuthLoading', true)
        commit('setAuthErrors', {})
        try {
            let response = await axios.post(`/api/email/verify/${data.id}/${data.hash}`,)
            commit('setAuthLoading', false)
            commit('setAuthErrors', {})
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setAuthLoading', false)
            commit('setAuthErrors', error.response.data.errors)
            return false
        }
    },

    async forgotPassword({commit}, data) {
        commit('setAuthLoading', true)
        commit('setAuthErrors', {})
        try {
            let response = await axios.post('/api/doctors/forgot-password', data)
            commit('setAuthLoading', false)
            if (response.data.ok) {
                var doctor = state.doctor
                doctor.phone_number = data.phone_number
                commit('setAuth', doctor)
                return true
            } else {
                return false
            }
        } catch (error) {
            console.log(error);
            commit('setAuthLoading', false)
            commit('setAuthErrors', error.response.data.errors)
            return false
        }
    },
    async code({commit}, data) {
        commit('setAuthLoading', true)
        commit('setAuthErrors', {})
        try {
            let response = await axios.post('/api/doctors/code', data)
            commit('setAuthLoading', false)
            if (response.data.ok) {
                var doctor = state.doctor
                doctor.phone_number = data.phone_number
                doctor.code = data.code
                commit('setAuth', doctor)
                return true
            } else {
                return false
            }
        } catch (error) {
            console.log(error);
            commit('setAuthLoading', false)
            commit('setAuthErrors', error.response.data.errors)
            return false
        }
    },
    async resetPassword({commit}, data) {
        commit('setAuthLoading', true)
        commit('setAuthErrors', {})
        try {
            let response = await axios.post('/api/doctors/reset-password', data)
            commit('setAuthLoading', false)
            if (response.data.ok) {
                commit('setAuth', response.data.doctor)
                window.localStorage.setItem('token', response.data.token)
                window.localStorage.setItem('doctor', JSON.stringify(response.data.user))
                return true
            } else {
                return false
            }
        } catch (error) {
            console.log(error);
            commit('setAuthLoading', false)
            commit('setAuthErrors', error.response.data.errors)
            return false
        }
    },


}

export default {
    state,
    getters,
    mutations,
    actions
}