const clouds =  [
    {
        path: '/clouds',
        name: 'clouds.index',
        component: () => import('@/pages/Cloud/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            need_project: true,
            layout: 'content',
            title: "Profil sozlamalari",
        }
    },
    {
        path: '/clouds/vps',
        name: 'clouds.vps.index',
        component: () => import('@/pages/Cloud/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            need_project: true,
            layout: 'content',
            title: "Profil sozlamalari",
        }
    },
    {
        path: '/clouds/vps/create',
        name: 'clouds.vps.create',
        component: () => import('@/pages/Cloud/CreatePage.vue'),
        meta: {
            need_project: true,
            requiresAuth: true,
            layout: 'content',
            title: "Profil sozlamalari",
        }
    },
]

export default clouds